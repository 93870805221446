"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const viewRender_1 = require("./restApi/endpoints/viewRender");
const SELECTOR_TOGGLE_BUTTON = '.js-async-children';
const ATTRIBUTE_FETCH_URL = 'data-fetch-url';
let placeholderMarkup = null;
const fetchMarkup = (url) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'credentials': 'same-origin'
        },
    });
    const { markup } = yield response.json();
    return markup;
});
function stringToHTML(str) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(str, "text/html");
    return htmlDoc.body.firstChild;
}
const getPlaceholderMarkup = () => __awaiter(void 0, void 0, void 0, function* () {
    return viewRender_1.viewRender.call({ routeParams: 'partials/preloader' })
        .then(htmlString => {
        return stringToHTML(htmlString);
    })
        .catch(error => {
        console.error(error.message);
        return null;
    });
});
const appendPlaceholder = (parentElement) => __awaiter(void 0, void 0, void 0, function* () {
    if (placeholderMarkup === null)
        return;
    parentElement.insertAdjacentElement('beforeend', placeholderMarkup);
});
const removePlaceholder = (parentElement) => {
    const placeholder = parentElement.querySelector('.placeholder');
    if (placeholder === null) {
        return;
    }
    parentElement.removeChild(placeholder);
};
const subscribeOnClick = (element) => {
    const handleClick = () => {
        // Parent of toggle has all states
        const parentElement = element.closest(".js-async-children-data");
        if (parentElement === null) {
            return;
        }
        const parentClassNames = [...parentElement.classList];
        // States
        const hasFetched = parentClassNames.includes('has-fetched');
        const isFetching = parentClassNames.includes('is-fetching');
        // Bye
        if (isFetching || hasFetched) {
            return;
        }
        // Input from attributes
        const fetchUrl = parentElement.getAttribute(ATTRIBUTE_FETCH_URL);
        if (!fetchUrl) {
            console.error('Fetch URL is not defined.');
            return;
        }
        // Set states before fetching
        appendPlaceholder(parentElement);
        parentElement.classList.add('is-fetching');
        parentElement.classList.add('is-loading');
        fetchMarkup(fetchUrl)
            .then(markup => {
            // Remove placeholder
            removePlaceholder(parentElement);
            // Render sub-menu
            parentElement.insertAdjacentHTML('beforeend', markup);
            // Set states
            parentElement.classList.remove('is-fetching');
            parentElement.classList.remove('is-loading');
            parentElement.classList.add('has-fetched');
            // Subscribe new toggles found in sub-menu recursively
            const newSubMenu = parentElement.lastElementChild;
            if (newSubMenu) {
                const newToggleButtons = newSubMenu.querySelectorAll(SELECTOR_TOGGLE_BUTTON);
                if (newToggleButtons && newToggleButtons.length > 0) {
                    newToggleButtons.forEach(subscribeOnClick);
                }
            }
        })
            .catch(e => {
            console.error(e);
            // Reset states
            parentElement.classList.remove('is-fetching');
            parentElement.classList.remove('is-loading');
        });
    };
    element.addEventListener('click', handleClick);
};
/**
* If the top level domain of the current URL is `translate.goog`, then return `true`, otherwise return
* `false`
* @returns A boolean value.
*/
function isCurrentlyBeingTranslated() {
    const hostChunks = window.location.hostname.split('.');
    const hostTop = hostChunks[hostChunks.length - 2] + '.' + hostChunks[hostChunks.length - 1];
    return 'translate.goog' === hostTop;
}
const init = () => __awaiter(void 0, void 0, void 0, function* () {
    /*
    * Hide language menu if the site is loaded with translate.goog as top level domain
    * to prevent google translate from opening multiple sites within.
    */
    if (isCurrentlyBeingTranslated()) {
        const languageMenu = document.getElementsByClassName('site-language-menu');
        if (languageMenu.length > 0) {
            [...languageMenu].forEach(element => {
                element.remove();
            });
        }
    }
    const toggleButtons = document.querySelectorAll(SELECTOR_TOGGLE_BUTTON);
    placeholderMarkup = yield getPlaceholderMarkup();
    if (toggleButtons && toggleButtons.length > 0) {
        toggleButtons.forEach(subscribeOnClick);
    }
});
exports.default = (() => {
    window.addEventListener('DOMContentLoaded', init);
})();
