"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.newEndpoint = exports.NameSpace = void 0;
var NameSpace;
(function (NameSpace) {
    NameSpace["MUNICIPIO_V1"] = "municipio/v1";
})(NameSpace = exports.NameSpace || (exports.NameSpace = {}));
const getNonce = () => {
    if (typeof wpApiSettings === 'undefined' || !wpApiSettings.nonce) {
        return null;
    }
    return wpApiSettings.nonce;
};
const getApiRoot = () => {
    if (typeof wpApiSettings === 'undefined' || !wpApiSettings.root) {
        return null;
    }
    return wpApiSettings.root;
};
const buildUrl = (nameSpace, route, routeParams, params) => {
    const apiRoot = getApiRoot();
    if (!apiRoot) {
        return '';
    }
    let url = `${apiRoot}${nameSpace}/${route}`;
    url += routeParams ? `/${routeParams}` : '';
    url += params ? '?' + new URLSearchParams(params) : '';
    return url;
};
const newEndpoint = (options) => ({
    call: (callArgs) => {
        const _a = callArgs !== null && callArgs !== void 0 ? callArgs : {}, { routeParams } = _a, data = __rest(_a, ["routeParams"]);
        const url = buildUrl(options.nameSpace, options.route, routeParams, options.method === 'GET' && data);
        const nonce = getNonce();
        const headers = {
            'Content-Type': 'application/json',
        };
        if (nonce) {
            headers['X-WP-Nonce'] = nonce;
        }
        const fetchOptions = {
            method: options.method,
            headers
        };
        if (options.method !== 'GET') {
            fetchOptions.body = JSON.stringify(callArgs);
        }
        return fetch(url, {
            method: options.method,
            body: options.method !== 'GET' ? JSON.stringify(callArgs) : undefined,
            headers
        }).then(response => {
            if (response.status === 200) {
                const contentType = response.headers.get("content-type");
                const contentTypeIsJson = (contentType && contentType.indexOf("application/json") !== -1);
                if (contentTypeIsJson) {
                    return response.json();
                }
                return response.text();
            }
            else {
                return response.statusText;
            }
        });
    },
});
exports.newEndpoint = newEndpoint;
