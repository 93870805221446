const language = {
  "en": "Language",
  "zh": "语言",
  "hi": "भाषा",
  "es": "Idioma",
  "ar": "لغة",
  "bn": "ভাষা",
  "pt": "Idioma",
  "ru": "Язык",
  "ja": "言語",
  "pa": "ਭਾਸ਼ਾ",
  "de": "Sprache",
  "jv": "Basa",
  "te": "భాష",
  "vi": "Ngôn ngữ",
  "ko": "언어",
  "fr": "Langue",
  "mr": "भाषा",
  "ta": "மொழி",
  "it": "Lingua",
  "tr": "Dil",
  "ur": "زبان",
  "uk": "Мова",
  "ml": "ഭാഷ",
  "tl": "Wika",
  "fa": "زبان",
  "th": "ภาษา",
  "gu": "ભાષા",
  "kn": "ಭಾಷೆ",
  "my": "ဘာသာစကား",
  "or": "ଭାଷା",
  "sd": "ھَسپَر",
  "nl": "Taal",
  "pl": "Język",
  "ro": "Limbă",
  "hu": "Nyelv",
  "sv": "Språk",
  "am": "ቋንቋ",
  "bs": "Jezik",
  "sr": "Језик",
  "hr": "Jezik",
  "ka": "ენა",
  "bg": "Език",
  "el": "Γλώσσα",
  "si": "භාෂාව",
  "mn": "Хэл",
  "mk": "Јазик",
  "lv": "Valoda",
  "lt": "Kalba",
  "et": "Keel",
  "hy": "Լեզու",
  "az": "Dil",
  "eu": "Hizkuntza",
  "be": "Мова",
  "ast": "Llingua",
  "is": "Tungumál",
  "ca": "Llengua",
  "af": "Taal",
  "ga": "Teanga",
  "sm": "Gagana",
  "to": "Lea faka-Tonga",
  "mi": "Reo",
  "haw": "Ōlelo",
  "so": "Luqadda",
  "tn": "Seisimane",
  "st": "Seisimane",
  "zu": "Ulimi",
  "xh": "Ulimi",
  "nso": "Seisimane",
  "ss": "isiSwati",
  "ve": "Lugha",
  "ts": "Xitsonga",
  "sn": "ChiShona",
  "sw": "Kiswahili",
  "yo": "Èdè",
  "ig": "Asụsụ",
  "ak": "Akan",
  "ha": "Harshen",
  "ff": "Pulaar",
  "bm": "Bamanankan",
  "ii": "ꆇꉙ",
  "kl": "Kalaallisut",
  "fy": "Frysk",
  "lb": "Lëtzebuergesch",
  "cy": "Cymraeg",
  "gd": "Gàidhlig",
  "kw": "Kernewek",
  "sco": "Scots",
  "br": "Brezhoneg",
  "oc": "Occitan",
  "rm": "Rumantsch",
  "fur": "Furlan",
  "lij": "Ligure",
  "scn": "Sicilianu",
  "wa": "Walon",
  "nap": "Nnapulitano",
  "lld": "Ladin",
  "frp": "Arpitan",
  "pms": "Piemontèis",
  "eml": "Emigliàn–Rumagnòl",
  "vec": "Vèneto"
};

export default language;
